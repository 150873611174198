
import DatePicker from "@/components/utils/IDatePicker.vue";
import ValidationMixin from "@/mixins/validation";
import {
  AddSystemProduct,
  ProductTypes,
} from "@/store/modules/product/product.types";
import { DefaultState } from "@/types/types";
import { Validator } from "@/types/validator";
import { Component, Mixins, Ref } from "vue-property-decorator";
import { namespace } from "vuex-class";

const productX = namespace("Product");

@Component({
  components: { DatePicker },
})
export default class AddProduct extends Mixins(ValidationMixin) {
  @productX.State(ProductTypes.ADD_PRODUCT_STATE)
  public addProduct!: DefaultState;

  @productX.Mutation(ProductTypes.SET_ADD_PRODUCT_DIALOG)
  public setAddProduct!: (addProduct: boolean) => void;

  @productX.Action(ProductTypes.ADD_PRODUCT)
  public saveProduct!: (farmer: AddSystemProduct) => Promise<void>;

  public addProductStep = 1;

  public isValid = false;

  public product: AddSystemProduct = {
    name: "",
    description: "",
    active: true,
  };

  @Ref("form") public formRef!: Validator;

  closeAddProductDialog(): void {
    this.setAddProduct(false);
  }

  async saveProductToDb(): Promise<void> {
    await this.formRef.validate();

    if (this.isValid) {
      await this.saveProduct(this.product);
    }
  }

  // mounted(): void {}
}
